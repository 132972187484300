import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getScore } from '../../actions/score';
import Spinner from '../../utils/Spiner';
import ScoreChart from '../student/ScoreChart';
const Score = () => {
  const dispatch = useDispatch();
  const scoreState = useSelector((state) => {
    return state.score.score;
  });
  useEffect(() => {
    getScore(dispatch);
  }, []);

  return scoreState === null ? (
    <Spinner />
  ) : (
    <>
      <ScoreChart scoreData={scoreState} />
    </>
  );
};

export default Score;
