import React from 'react';

import { useEffect, useState } from 'react';
import Spinner from '../../utils/Spiner';
import AttendanceChart from './AttendanceChart';
import axios from 'axios';
const Attendance = () => {
  const [attendanceState, setAttendanceState] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/api/attendance/me/`);
      if (!res) {
        throw new Error('データがない');
      }
      setAttendanceState(res.data);
    };
    fetchData();
  }, []);
  return attendanceState === null ? (
    <Spinner />
  ) : (
    <>
      <AttendanceChart attendanceData={attendanceState} />
    </>
  );
};

export default Attendance;
