import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  user: null,
  loading:true
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // registerSuccess(state, action) {
    //   localStorage.setItem('token', action.payload.token);
    //   return {
    //     ...state,
    //     ...action.payload,
    //     isAuthenticated: true,
    //   };
    // },
    // registerFail(state) {
    //   localStorage.removeItem('token');
    //   return {
    //     ...state,
    //     token: null,
    //     isAuthenticated: false,
    //     loading: false,
    //   };
    // },
    loginSuccess(state, action) {
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
      };
    },
    loginFail(state) {
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    },
    userLoad(state, action) {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false,
      };
    },
    authError(state) {
      // localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    },
    logout(state) {
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    },
  },
});
export const authActions = authSlice.actions;
export default authSlice.reducer;
//このstoreはおそらく，indexjsなどでstoreを使える際に必要なため作成している．
