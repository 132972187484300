import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
//認証情報がローカルストレージにあるかどうかで，表示するリソースを変えている．
const PrivateRoute = () => {
  const state = useSelector((state) => {
    return state.auth.isAuthenticated;
  });
  return state ? <Outlet /> : <Navigate to='/' />;
};

export default PrivateRoute;
