import React from 'react';
import { useState} from 'react';

import {Navigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../actions/auth';



// import auth from '../../../../middleware/auth';

//管理者が生徒のアカウントを作成するページ
const Mainh1 = styled('h1')(({ theme }) => ({
  color: 'green',
  fontSize: '3rem',
  textAlign: 'center',
  margin: 0,
  // marginTop: "6rem",
  // marginBottom: "12rem",
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
    // marginTop: "4rem",
    // marginBottom: "8rem",
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
    // marginTop: "auto",
    // marginBottom: "auto",
  },
}));

const MyButton = styled(Button)(({ theme }) => ({
  ...theme.typography.basic,
  height: '4rem',
  backgroundColor: 'green',
  width: '15rem',
  '&:hover': {
    backgroundColor: 'white',
  },
  [theme.breakpoints.down('md')]: {
    height: '6rem',
    width: '20rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    height: '4rem',
    width: '15rem',
  },
}));

const FormTextField = styled(TextField)(({ theme }) => ({
  height: '4rem',

  width: '20rem',
  // '&:hover': {
  //   backgroundColor: 'white',
  //   opacity: '0.7',
  // },
  [theme.breakpoints.down('md')]: {
    height: '3rem',
    width: '15rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    height: '2rem',
    width: '10rem',
  },
}));

const Login = () => {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   loadUser(dispatch);
  //   console.log('qdeadwadadwa');
  // }, []);
  const isAuthenticated = useSelector((state) => {
    return state.auth.isAuthenticated;
  });
  //  const show = useSelector((state) => {
  //    return state.showState;
  //  });
  const [formData, setFormData] = useState({
    user_id: '',
    password: '',
  });
  const { user_id, password } = formData;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    login(user_id, password, dispatch);
  };
  if (isAuthenticated) {
    return <Navigate to='/attendance' />;
  }
return (
    <>
      <Grid
        container
        spacing={5}
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ marginTop: '10px' }}
      >
        <Grid item xs={12}>
          <Mainh1>Login</Mainh1>
        </Grid>
        <Grid item xs={12}>
          <p>生徒のログイン画面</p>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={(e) => onSubmit(e)}>
            <Grid
              container
              spacing={5}
              direction='column'
              justifyContent='center'
              alignItems='center'
            >
              <Grid item xs={12}>
                <FormTextField
                  type='text'
                  placeholder='ユーザ名'
                  name='user_id'
                  value={user_id}
                  onChange={(e) => onChange(e)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  type='password'
                  placeholder='Password'
                  name='password'
                  value={password}
                  onChange={(e) => onChange(e)}
                  minLength='6'
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <MyButton type='submit'>ログイン</MyButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
