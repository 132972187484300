import React from 'react';
// import { useEffect } from 'react';
import { useSelector } from 'react-redux';
const StudentInfo = () => {
  // const dispatch = useDispatch();
  const studentState = useSelector((state) => {
    return state.auth.user.name;
  });

  return <>{studentState}</>;
};

export default StudentInfo;
