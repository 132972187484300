import React from 'react';
import Spinner from '../../utils/Spiner';
import { useState, useEffect } from 'react';
import StudentAllChart from './StudentAllChart';
import axios from 'axios';
const DashBoard = () => {
  const [studentState, setStudentState] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get('/api/admin/user');
      if (!res) {
        throw new Error('データがない');
      }

      setStudentState(res.data);
    };
    fetchData();
  }, []);

  return studentState === null ? (
    <Spinner />
  ) : (
    <>
      <StudentAllChart studentData={studentState} />
    </>
  );
};

export default DashBoard;
