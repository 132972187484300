import { React, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
// import _ from 'lodash';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
// import axios from 'axios';
import ClickAwayListener from '@mui/base/ClickAwayListener';

const StudentAllChart = ({ studentData }) => {

  //表示用の状態管理
  const [idStatus, setIdStatus] = useState(studentData);
  //更新用の状態管理
  const [changedStatus, setChangedStatus] = useState([]);
  const [rowIndex, setRowIndex] = useState(-1);
  const [columnIndex, setColumnIndex] = useState(-1);
  const MyButton = styled(Button)(({ theme }) => ({
    ...theme.typography.miniButton,
    height: '4rem',
    backgroundColor: 'orange',
    width: '6rem',
    marginRight: '10px',
    marginLeft: '10px',
    '&:hover': {
      // backgroundColor: 'white',
      opacity: 0.7,
    },
    [theme.breakpoints.down('md')]: {
      height: '6rem',
      width: '20rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      height: '4rem',
      width: '15rem',
    },
  }));
  const MyPaper = styled(Paper)(() => ({
    marginTop: '50px',
    // console.log(theme);
    // backgroundColor: theme.palette.primary.main,
  }));

  /**
   *
   * @param {*} e 入力された内容
   * @param {*} id 入力中のレコードのID
   * @param {*} hurigana 入力中のレコードのふりがな
   * @param {*} card_is_Valid 入力中のカードの有効可否
   * @param {*} term 入力中のterm
   * @param {*} grade 入力中のレコードの学年
   */
  const handleChange = (e, name, id, hurigana, card_is_Valid, term, grade) => {
    // console.log(id);
    const result = idStatus.find((element) => {
      return element.id === id;
    });
    const changedResult = changedStatus.find((element) => {
      return element.id === id;
    });

    switch (e.target.name) {
      case 'name':
        if (!result) {
          setIdStatus([
            ...idStatus,
            {
              id: id,
              name: e.target.value,
              hurigana,
              card_is_Valid,
              term,
              grade,
            },
          ]);
        } else {
          setIdStatus((prevState) =>
            prevState.map((obj) =>
              obj.id === id
                ? {
                    id: obj.id,
                    name: e.target.value,
                    hurigana,
                    card_is_Valid,
                    term,
                    grade,
                  }
                : obj
            )
          );
        }
        if (!changedStatus.length || changedResult === undefined) {

          setChangedStatus([
            ...changedStatus,
            {
              id,
              name: e.target.value,
              hurigana,
              card_is_Valid,
              term,
              grade,
            },
          ]);
        } else {

          setChangedStatus((prevState) =>
            prevState.map((obj) =>
              obj.id === id
                ? {
                    id: obj.id,
                    name: e.target.value,
                    hurigana,
                    card_is_Valid,
                    term,
                    grade,
                  }
                : obj
            )
          );
        }
        break;
      case 'hurigana':
        if (!result) {
          setIdStatus([
            ...idStatus,
            {
              id: id,
              name,
              hurigana: e.target.value,
              card_is_Valid,
              term,
              grade,
            },
          ]);
        } else {
          setIdStatus((prevState) =>
            prevState.map((obj) =>
              obj.id === id
                ? {
                    id: obj.id,
                    name,
                    hurigana: e.target.value,
                    card_is_Valid,
                    term,
                    grade,
                  }
                : obj
            )
          );
        }
        if (!changedStatus.length || changedResult === undefined) {

          setChangedStatus([
            ...changedStatus,
            {
              id,
              name,
              hurigana: e.target.value,
              card_is_Valid,
              term,
              grade,
            },
          ]);
        } else {

          setChangedStatus((prevState) =>
            prevState.map((obj) =>
              obj.id === id
                ? {
                    id: obj.id,
                    name,
                    hurigana: e.target.value,
                    card_is_Valid,
                    term,
                    grade,
                  }
                : obj
            )
          );
        }
        break;
      case 'card_is_Valid':
        if (!result) {
          setIdStatus([
            ...idStatus,
            {
              id: id,
              name,
              hurigana,
              card_is_Valid: e.target.value,
              term,
              grade,
            },
          ]);
        } else {
          setIdStatus((prevState) =>
            prevState.map((obj) =>
              obj.id === id
                ? {
                    id: obj.id,
                    name,
                    hurigana,
                    card_is_Valid: e.target.value,
                    term,
                    grade,
                  }
                : obj
            )
          );
        }
        if (!changedStatus.length || changedResult === undefined) {

          setChangedStatus([
            ...changedStatus,
            {
              id,
              name,
              hurigana,
              card_is_Valid: e.target.value,
              term,
              grade,
            },
          ]);
        } else {
          setChangedStatus((prevState) =>
            prevState.map((obj) =>
              obj.id === id
                ? {
                    id: obj.id,
                    name,
                    hurigana,
                    card_is_Valid: e.target.value,
                    term,
                    grade,
                  }
                : obj
            )
          );
        }
        break;
      case 'term':
        if (!result) {
          setIdStatus([
            ...idStatus,
            {
              id: id,
              name,
              hurigana,
              card_is_Valid,
              term: e.target.value,
              grade,
            },
          ]);
        } else {
          setIdStatus((prevState) =>
            prevState.map((obj) =>
              obj.id === id
                ? {
                    id: obj.id,
                    name,
                    hurigana,
                    card_is_Valid,
                    term: e.target.value,
                    grade,
                  }
                : obj
            )
          );
        }
        if (!changedStatus.length || changedResult === undefined) {
          setChangedStatus([
            ...changedStatus,
            {
              id,
              name,
              hurigana,
              card_is_Valid,
              term: e.target.value,
              grade,
            },
          ]);
        } else {
          setChangedStatus((prevState) =>
            prevState.map((obj) =>
              obj.id === id
                ? {
                    id: obj.id,
                    name,
                    hurigana,
                    card_is_Valid,
                    term: e.target.value,
                    grade,
                  }
                : obj
            )
          );
        }
        break;
      case 'grade':
        if (!result) {
          setIdStatus([
            ...idStatus,
            {
              id: id,
              name,
              hurigana,
              card_is_Valid,
              term,
              grade: e.target.value,
            },
          ]);
        } else {
          setIdStatus((prevState) =>
            prevState.map((obj) =>
              obj.id === id
                ? {
                    id: obj.id,
                    name,
                    hurigana,
                    card_is_Valid,
                    term,
                    grade: e.target.value,
                  }
                : obj
            )
          );
        }
        if (!changedStatus.length || changedResult === undefined) {
          setChangedStatus([
            ...changedStatus,
            {
              id,
              name,
              hurigana,
              card_is_Valid,
              term,
              grade: e.target.value,
            },
          ]);
        } else {
          setChangedStatus((prevState) =>
            prevState.map((obj) =>
              obj.id === id
                ? {
                    id: obj.id,
                    name,
                    hurigana,
                    card_is_Valid,
                    term,
                    grade: e.target.value,
                  }
                : obj
            )
          );
        }
        break;
    }
  };
  const handleExit = () => {
    setRowIndex(-1);
    setColumnIndex(-1);
  };
  //更新ボタンの処理
  const handleSubmit = async (e) => {
    e.preventDefault();

    // const res = await axios.put('/api/admin/user', changedStatus);

  };

  return (
    <>
      <ClickAwayListener onClickAway={() => handleExit()}>
        <TableContainer>
          <Table component={MyPaper}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>学籍番号</TableCell>
                <TableCell>生徒名</TableCell>
                <TableCell>ふりがな</TableCell>
                <TableCell>学年</TableCell>
                <TableCell>ターム</TableCell>
                <TableCell>コース名</TableCell>
                <TableCell>カードの有効可否</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentData.map((ele, index) => (
                <TableRow key={index}>
                  <TableCell>{ele.id}</TableCell>
                  <TableCell>{ele.student_id}</TableCell>
                  <TableCell
                    onClick={() => {
                      setRowIndex(index);
                      setColumnIndex(0);
                    }}
                  >
                    {rowIndex === index && columnIndex === 0 ? (
                      <TextField
                        id={`name_${index}`}
                        defaultValue={idStatus[index]['name']}
                        name='name'
                        onBlur={(e) => {
                          handleChange(
                            e,
                            idStatus[index]['name'],
                            idStatus[index]['id'],
                            idStatus[index]['hurigana'],
                            idStatus[index]['card_is_Valid'],
                            idStatus[index]['term'],
                            idStatus[index]['grade']
                          );
                        }}
                        // onKeyPress={(e) => {
                        //   if (e.key === 'Enter') {
                        //     handleExit();
                        //   }
                        // }}
                      />
                    ) : (
                      idStatus[index]['name']
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setRowIndex(index);
                      setColumnIndex(0);
                    }}
                  >
                    {rowIndex === index && columnIndex === 0 ? (
                      <TextField
                        id={`hurigana_${index}`}
                        defaultValue={idStatus[index]['hurigana']}
                        name='hurigana'
                        onBlur={(e) => {
                          handleChange(
                            e,
                            idStatus[index]['name'],
                            idStatus[index]['id'],
                            idStatus[index]['hurigana'],
                            idStatus[index]['card_is_Valid'],
                            idStatus[index]['term'],
                            idStatus[index]['grade']
                          );
                        }}
                      />
                    ) : (
                      idStatus[index]['hurigana']
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setRowIndex(index);
                      setColumnIndex(0);
                    }}
                  >
                    {rowIndex === index && columnIndex === 0 ? (
                      <TextField
                        id={`grade_${index}`}
                        defaultValue={idStatus[index]['grade']}
                        name='grade'
                        onBlur={(e) => {
                          handleChange(
                            e,
                            idStatus[index]['name'],
                            idStatus[index]['id'],
                            idStatus[index]['hurigana'],
                            idStatus[index]['card_is_Valid'],
                            idStatus[index]['term'],
                            idStatus[index]['grade']
                          );
                        }}
                        // onKeyPress={(e) => {
                        //   if (e.key === 'Enter') {
                        //     handleExit();
                        //   }
                        // }}
                      />
                    ) : (
                      idStatus[index]['grade']
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setRowIndex(index);
                      setColumnIndex(0);
                    }}
                  >
                    {rowIndex === index && columnIndex === 0 ? (
                      <TextField
                        id={`term_${index}`}
                        defaultValue={idStatus[index]['term']}
                        name='term'
                        onBlur={(e) => {
                          handleChange(
                            e,
                            idStatus[index]['name'],
                            idStatus[index]['id'],
                            idStatus[index]['hurigana'],
                            idStatus[index]['card_is_Valid'],
                            idStatus[index]['term'],
                            idStatus[index]['grade']
                          );
                        }}
                        // onKeyPress={(e) => {
                        //   if (e.key === 'Enter') {
                        //     handleExit();
                        //   }
                        // }}
                      />
                    ) : (
                      idStatus[index]['term']
                    )}
                  </TableCell>
                  <TableCell>{ele.course_name}</TableCell>
                  <TableCell
                    onClick={() => {
                      setRowIndex(index);
                      setColumnIndex(0);
                    }}
                  >
                    {rowIndex === index && columnIndex === 0 ? (
                      <TextField
                        id={`card_is_Valid_${index}`}
                        defaultValue={idStatus[index]['card_is_Valid']}
                        name='card_is_Valid'
                        onBlur={(e) => {
                          handleChange(
                            e,
                            idStatus[index]['name'],
                            idStatus[index]['id'],
                            idStatus[index]['hurigana'],
                            idStatus[index]['card_is_Valid'],
                            idStatus[index]['term'],
                            idStatus[index]['grade']
                          );
                        }}
                        // onKeyPress={(e) => {
                        //   if (e.key === 'Enter') {
                        //     handleExit();
                        //   }
                        // }}
                      />
                    ) : (
                      idStatus[index]['card_is_Valid']
                    )}
                  </TableCell>
                  <MyButton
                    component={Link}
                    to={`/dashboard/attendance/${ele.id}`}
                  >
                    出席情報
                  </MyButton>
                  <MyButton
                    component={Link}
                    to={`/dashboard/attendance/edit/${ele.id}`}
                  >
                    出席情報更新画面
                  </MyButton>
                  <MyButton
                    component={Link}
                    to={`/dashboard/attendance/create/${ele.id}`}
                  >
                    出席情報作成画面
                  </MyButton>
                  <MyButton component={Link} to={`/dashboard/score/${ele.id}`}>
                    成績情報
                  </MyButton>
                  {/* <MyButton component={Link} to='/dashboard/info'>
                    個人情報
                  </MyButton> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ClickAwayListener>
      <MyButton
        onClick={(e) => {
          handleSubmit(e);
        }}
        // component={Link}
        // to='/dashboard'
      >
        更新
      </MyButton>
    </>
  );
};
export default StudentAllChart;
