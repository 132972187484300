import React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
//import Box from "@mui/material/Box";
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
//import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
//import { useTheme } from "@mui/material/styles";
import LogoutIcon from '@mui/icons-material/Logout';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { adminLogout } from '../../actions/authAdmin';
import { logout } from '../../actions/auth';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
//スクロールを行う際に使用するpropsの型定義

//スクロールを行う関数
function ElevationScroll(props) {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  //ここの細かいキーについては，後で見とく必要あり．
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    // target: window ? window() : undefined,
  });
  //4は浮遊している．0はスクロールされていない状態を表している
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

//これは，toolbarのdefaultの高さと一緒のdiv要素を作成している．
const MyThemeComponent = styled('div')(({ theme }) => ({
  // console.log(theme);
  ...theme.mixins.toolbar,
  marginTop: '10px',
  // backgroundColor: theme.palette.primary.main,
}));
const MyTabs = styled(Tabs)(() => ({

  marginLeft: 'auto',
  // backgroundColor: theme.palette.primary.main,
}));
//本来であれば，ここにMyTabのカスタムコンポーネントを作成して，デザインをカスタマイズしていく．
const MyTab = styled(Tab)(({ theme }) => ({
  ...theme.typography.tab,
  marginLeft: '20px',

}));

const MyButton = styled(Button)(({ theme }) => ({
  ...theme.typography.logout,
  borderRadius: '30px',
  marginLeft: '10px',

}));
const LogoButton = styled(Button)(() => ({
  color: 'white',
  marginLeft: '10px',
  borderRadius: '30px',
  fontSize: '35px',
  fontWeight: 600,

}));
//Menu関連
const MyDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.common.blue,
    color: 'white',
    borderRadius: '0px',
  },
}));
//Menu関連
const MyMenu = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    backgroundColor: theme.palette.common.blue,
    color: 'white',
    borderRadius: '0px',
  },
}));
const MyMenuItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.tab,
  opacity: 0.8,
  '&:hover': {
    opacity: 1,
  },
}));
// as typeof MenuItem;
function Header(props) {
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => {
    return state.auth.isAuthenticated;
  });
  const adminIsAuthenticated = useSelector((state) => {
    return state.authAdmin.isAuthenticated;
  });
  // const loading = useSelector((state) => {
  //   return state.auth.loading;
  // });
  // const adminLoading = useSelector((state) => {
  //   return state.authAdmin.loading;
  // });
  // const adminLoading = useSelector((state) => {
  //   return state.authAdmin.loading;
  // });
  //const theme = useTheme();
  const [value, setValue] = useState(0);
  //どのコンポを保存しておくかを決めているuseState
  const [anchorEl, setAnchorEl] = useState();
  //実際にメニューが表示されているかどうかを決めている．
  const open = Boolean(anchorEl);

  useEffect(() => {
    switch (window.location.pathname) {
      case '/':
        // case '/syllabus':
        if (value !== 0) {
          setValue(0);
        }
        break;
      case '/info':
        if (value !== 1) {
          setValue(1);
          // setSelectedIndex(0);
        }
        break;
      case '/score':
        if (value !== 2) {
          setValue(2);
          // setSelectedIndex(0);
        }
        break;
      default:
    }
  }, [value]);
  //Tabを押下したときのMenuを出す関数
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //ここの型注釈はどうするの？
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const authLinks = (
    <Toolbar disableGutters>
      <LogoButton
        onClick={() => {
          setValue(0);
        }}
        component={Link}
        to='/'
      >
        STANESS
      </LogoButton>
      <MyTabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{ style: { background: 'white' } }}
        textColor='inherit'
      >
        <MyTab
          onClick={handleClick}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup={anchorEl ? true : undefined}
          label='出席・授業管理'
          component={Link}
          to='/'
        />
        <MyTab label='個人情報管理' component={Link} to='/info' />

        <MyTab label='成績管理' component={Link} to='/score' />
      </MyTabs>

      <MyButton
        disableRipple
        variant='contained'
        color='secondary'
        onClick={() => {
          logout(dispatch);
        }}
        component={Link}
        to='/'
      >
        ログアウト
        <LogoutIcon />
      </MyButton>
      <MyMenu
        id='simple-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        <MyMenuItem
          onClick={() => {
            handleClose();
            setValue(0);
          }}
          component={Link}
          to='/attendance'
        >
          出欠状況確認
        </MyMenuItem>
        <MyMenuItem
          onClick={() => {
            handleClose();
            setValue(0);
          }}
          component={Link}
          to='/syllabus'
        >
          シラバス確認
        </MyMenuItem>
      </MyMenu>
    </Toolbar>
  );
  const guestLink = (
    <Toolbar disableGutters>
      <LogoButton> STANESS</LogoButton>
    </Toolbar>
  );
  const adminLink = (
    <Toolbar disableGutters>
      <LogoButton
        onClick={() => {
          setValue(0);
        }}
        component={Link}
        to='/'
      >
        STANESS
      </LogoButton>

      <MyButton
        disableRipple
        variant='contained'
        color='secondary'
        onClick={() => {
          adminLogout(dispatch);
        }}
        component={Link}
        to='/admin/login'
      >
        ログアウト
        <LogoutIcon />
      </MyButton>
    </Toolbar>
  );
  const displayMobile = () => {
    const handleDrawerOpen = () => {
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    };
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    return (
      <Toolbar>
        <IconButton
          edge='start'
          color='inherit'
          aria-label='menu'
          aria-haspopup='true'
          onClick={handleDrawerOpen}
        >
          <DehazeIcon />
        </IconButton>
        <MyDrawer anchor='left' open={drawerOpen} onClose={handleDrawerClose}>
          {adminIsAuthenticated && (
            <ListItem
              component={Link}
              to='/admin/login'
              onClick={() => {
                adminLogout(dispatch);
              }}
            >
              ログアウト
            </ListItem>
          )}
          {isAuthenticated && (
            <Box>
              <ListItem
                component={Link}
                to='/attendance'
                sx={{ textDecoration: 'none', color: 'inherit' }}
              >
                出席状況確認
              </ListItem>
              <ListItem
                component={Link}
                to='/info'
                sx={{ textDecoration: 'none', color: 'inherit' }}
              >
                個人情報確認
              </ListItem>
              <ListItem
                component={Link}
                to='/score'
                sx={{ textDecoration: 'none', color: 'inherit' }}
              >
                成績確認
              </ListItem>
              <ListItem
                component={Link}
                to='/'
                sx={{ textDecoration: 'none', color: 'inherit' }}
                onClick={() => {
                  logout(dispatch);
                }}
              >
                ログアウト
              </ListItem>
            </Box>
          )}
        </MyDrawer>
        <div>STANESS</div>
      </Toolbar>
    );
  };
  // if (isAuthenticated) {
  //   return <Navigate to='/attendance' />;
  // }
  return (
    <>
      <ElevationScroll {...props}>
        <AppBar position='fixed'>
          {mobileView
            ? displayMobile()
            : (!adminIsAuthenticated &&
                (isAuthenticated ? authLinks : guestLink)) ||
              (adminIsAuthenticated && adminLink)}
          {/* {!adminIsAuthenticated && (
            <>{isAuthenticated ? authLinks : guestLink}</>
          )}
          {adminIsAuthenticated ? adminLink : <></>} */}
        </AppBar>
      </ElevationScroll>
      <MyThemeComponent />
    </>
  );
}

export default Header;
