import React from 'react';

import { useEffect, useState } from 'react';

import Spinner from '../../utils/Spiner';
import AdminAttendanceCreateChart from './AdminAttendanceCreateChart';
import { useParams } from 'react-router-dom';
import axios from 'axios';
const AdminAttendance = () => {
  const params = useParams();
  const [attendanceState, setAttendanceState] = useState(null);

  // const attendanceState = useSelector((state) => {
  //   return state.attendance.attendances;
  // });
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/api/attendance/edit/user/${params.stid}/`);
      if (!res) {
        throw new Error('データがない');
      }

      setAttendanceState(res.data);
    };
    fetchData();
  }, []);

  return attendanceState === null ? (
    <Spinner />
  ) : (
    <>
      <AdminAttendanceCreateChart attendanceData={attendanceState} />
    </>
  );
};

export default AdminAttendance;
