import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  adminState: null,
  loading: true,
};

const authAdminSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    adminLoginSuccess(state, action) {
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
      };
    },
    adminLoginFail(state) {
      // localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    },
    adminUserLoad(state, action) {
      return {
        ...state,
        isAuthenticated: true,
        adminState: action.payload,
        loading: false,
      };
    },
    adminAuthError(state) {
      // localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        deleted: true,
        loading: false,
      };
    },
    adminLogout(state) {
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    },
  },
});
export const authAdminActions = authAdminSlice.actions;
export default authAdminSlice.reducer;
//このstoreはおそらく，indexjsなどでstoreを使える際に必要なため作成している．
