import axios from 'axios';

import { scoreActions } from '../store/score';

export const getScore = async (dispatch) => {
  try {
    const res = await axios.get('/api/score/me/');
    dispatch(scoreActions.scoreSuccess(res.data));
  } catch (error) {
    dispatch(
      scoreActions.scoreError({
        msg: error.response.statusText,
        status: error.response.status,
      })
    );
  }
};
