import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const PrivateAdminRoute = () => {
  const state = useSelector((state) => {
    return state.authAdmin.isAuthenticated;
  });
  const loading = useSelector((state) => {
    return state.authAdmin.loading;
  });
  return !state && !loading ? <Navigate to='/admin/login' /> : <Outlet />;
};

export default PrivateAdminRoute;
