import { React, useState } from 'react';
// import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ClickAwayListener from '@mui/base/ClickAwayListener';
// import _ from 'lodash';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import axios from 'axios';

// import AttendanceTextField from './AttendanceTextField';
const AdminAttendanceEditChart = ({ attendanceData }) => {
  const attendances = attendanceData;
  //表の全状態を管理
  const idStatusData = attendances.map((ele) => {
    return { id: ele.id, status: ele.status };
  });


  const [idStatus, setIdStatus] = useState(idStatusData);
  const [rowIndex, setRowIndex] = useState(-1);
  const [columnIndex, setColumnIndex] = useState(-1);
  //変更された状態を管理
  const [changedStatus, setChangedStatus] = useState([]);

  const MyButton = styled(Button)(({ theme }) => ({
    ...theme.typography.miniButton,
    height: '4rem',
    backgroundColor: 'orange',
    margin: 'auto',
    textAlign: 'center',
    width: '6rem',

    padding: '0px',
    '&:hover': {
      // backgroundColor: 'white',
      opacity: 0.7,
    },
    [theme.breakpoints.down('md')]: {
      height: '6rem',
      width: '20rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      height: '4rem',
      width: '15rem',
    },
  }));
  const MyPaper = styled(Paper)(() => ({
    marginTop: '50px',
    // console.log(theme);
    // backgroundColor: theme.palette.primary.main,
  }));
  const handleChange = (e, id) => {
    // console.log(id);
    const result = idStatus.find((element) => {
      return element.id === id;
    });
    if (!result) {
      setIdStatus([...idStatus, { id: id, status: e.target.value }]);
    } else {
      setIdStatus((prevState) =>
        prevState.map((obj) =>
          obj.id === id ? { id: obj.id, status: e.target.value } : obj
        )
      );
    }
    const changedResult = changedStatus.find((element) => {
      return element.id === id;
    });
    //最初の一回・同じidが定義されていない時
    if (!changedStatus.length || changedResult === undefined) {
      setChangedStatus([...changedStatus, { id: id, status: e.target.value }]);
    } else {
      setChangedStatus((prevState) =>
        prevState.map((obj) =>
          obj.id === id ? { id: obj.id, status: e.target.value } : obj
        )
      );
    }
  };
  const handleExit = () => {
    setRowIndex(-1);
    setColumnIndex(-1);
  };
  const handleSubmit = async(e) => {
    e.preventDefault();

    // const res = await axios.put('/api/attendance/user', changedStatus);

  };

  return (
    <Grid
      container
      spacing={5}
      direction='column'
      justifyContent='center'
      alignItems='center'
      sx={{ marginTop: '10px' }}
    >
      <Grid item xs={12}>
        <ClickAwayListener onClickAway={() => handleExit()}>
          <TableContainer sx={{ marginBottom: '100px' }}>
            <Table component={MyPaper}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>出席ステータス</TableCell>
                  <TableCell>タッチ日時</TableCell>
                  <TableCell>作成日</TableCell>
                  <TableCell>更新日</TableCell>
                  <TableCell>生徒ID</TableCell>
                  <TableCell>授業ID</TableCell>
                  <TableCell>コースID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendances.map((ele, index) => (
                  <TableRow key={index}>
                    <TableCell>{ele.id}</TableCell>
                    <TableCell
                      onClick={() => {
                        setRowIndex(index);
                        setColumnIndex(0);
                      }}
                    >
                      {rowIndex === index && columnIndex === 0 ? (
                        <TextField
                          id={index}
                          defaultValue={idStatus[index]['status']}
                          // value={idStatus[index]['status']}
                          onBlur={(e) => {
                            handleChange(e, ele.id);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleExit();
                            }
                          }}
                          key={index}
                        />
                      ) : (
                        idStatus[index]['status']
                      )}
                    </TableCell>
                    <TableCell>{ele.date}</TableCell>
                    <TableCell>{ele.createdAt}</TableCell>
                    <TableCell>{ele.updatedAt}</TableCell>
                    <TableCell>{ele.studentId}</TableCell>
                    <TableCell>{ele.classId}</TableCell>
                    <TableCell>{ele.courseId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ClickAwayListener>
      </Grid>
      <Grid item xs={12}>
        <MyButton
          onClick={(e) => {
            handleSubmit(e);
          }}
          // component={Link}
          // to='/dashboard'
        >
          更新
        </MyButton>
      </Grid>
    </Grid>
  );
};
export default AdminAttendanceEditChart;
