import React from 'react';
import { useEffect, useState } from 'react';
import Spinner from '../../utils/Spiner';
import AdminScoreChart from './AdminScoreChart';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const AdminScore = () => {
  const params = useParams();
  const [scoreState, setScoreState] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/api/score/user/${params.stid}/`);
      if (!res) {
        throw new Error('データがない');
      }

      setScoreState(res.data);
    };
    fetchData();
  }, []);
 
  return scoreState === null ? (
    <Spinner />
  ) : (
    <>
      <AdminScoreChart scoreData={scoreState} />
    </>
  );
};

export default AdminScore;
