import { authAdminActions } from '../../src/store/authAdmin';
import axios from 'axios';
// import { authActions } from '../../src/store/auth';
import setAuthToken from '../utils/setAuthToken';
/**
 * 管理者が生徒の情報を登録する際に，dispatchする
 * @param {*} name 名前
 * @param {*} hurigana 名前のふりがな
 * @param {*} student_id 学籍番号
 * @param {*} card_num カード番号
 * @param {*} course_id コース番号
 * @param {*} user_id ユーザid
 * @param {*} password パスワード
 * @param {*} dispatch useDispatch
 */
// export const register = async (
//   name,
//   hurigana,
//   student_id,
//   card_num,
//   course_id,
//   user_id,
//   password,
//   dispatch
// ) => {
//   const header = {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   };
//   const body = JSON.stringify({
//     name,
//     hurigana,
//     student_id,
//     card_num,
//     course_id,
//     user_id,
//     password,
//   });
//   try {
//     const res = await axios.post('/api/admin/register', body, header);
//     dispatch(authActions.registerSuccess(res.data));
//     // loadUser(dispatch);
//   } catch (err) {
//     dispatch(authActions.registerFail());
//   }
// };
/**
 *管理者がログインする時に，dispatchする
 * @param {*} user_id ユーザid
 * @param {*} password パスワード
 * @param {*} dispatch useDispatch
 */
export const adminLogin = async (user_id, password, dispatch) => {
  const header = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    user_id,
    password,
  });
  try {
    const res = await axios.post('/api/auth/admin/login', body, header);
    dispatch(authAdminActions.adminLoginSuccess(res.data));
    loadAdminUser();
    // loadUser(dispatch);
  } catch (err) {
    dispatch(authAdminActions.adminLoginFail());
  }
};
/**
 * ローカルストレージのtokenをヘッダーにいれて，adminState情報を取得し，reduxのstoreに格納している
 * @param {*} dispatch useDispatch
 */
export const loadAdminUser = async (dispatch) => {
  //ローカルストレージにtokenがあれば，ヘッダーにセット．
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get('/api/auth/admin');
    dispatch(authAdminActions.adminUserLoad(res.data));
  } catch (err) {
    dispatch(authAdminActions.adminAuthError());
  }
};
export const adminLogout = (dispatch) => {
  dispatch(authAdminActions.adminLogout());
};
