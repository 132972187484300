//出席状況確認ページで更新も実施しようとしてた時のコード
// import { React, useState } from 'react';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import _ from 'lodash';
// import Box from '@mui/material/Box';
// import { styled } from '@mui/material/styles';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import axios from 'axios';
// import ClickAwayListener from '@mui/base/ClickAwayListener';

// const AttendanceChart = ({ attendanceData }) => {
//   let attendances = JSON.parse(JSON.stringify(attendanceData)); //表示用に使用
//   let editAttendances = JSON.parse(JSON.stringify(attendanceData)); //更新用に使用
//   //表全体の状態を管理
//   const [idStatus, setIdStatus] = useState(editAttendances);
//   const [rowIndex, setRowIndex] = useState(-1);
//   const [columnIndex, setColumnIndex] = useState(-1);
//   //変更された状態を管理
//   const [changedStatus, setChangedStatus] = useState([]);

//   const [value, setValue] = useState(0);
//   console.log(attendances);

//   console.log(editAttendances);

//   /**
//    * term,grade,year,授業名が一致する，statusをまとめている．(表示するために．)
//    */
//   let result = [];
//   let totalStatus = '';
//   for (let i = 0; i < attendances.length - 1; i++) {
//     totalStatus = attendances[i].status;
//     for (let j = i + 1; j < attendances.length; j++) {
//       if (
//         attendances[i].term === attendances[j].term &&
//         attendances[i].grade === attendances[j].grade &&
//         attendances[i].year === attendances[j].year &&
//         attendances[i].name === attendances[j].name
//       ) {
//         totalStatus += attendances[j].status;
//         attendances[j].status = '';
//         // attendances.splice(j, 1);
//       }
//     }
//     if (totalStatus) {
//       result.push({
//         ...attendances[i],
//         status: totalStatus,
//       });
//     }
//   }
//   console.log(result);

//   // const newResult = _.groupBy(editAttendances, (ele) => {
//   //   return ele.year;
//   // });
//   const newResult = _.groupBy(result, (ele) => {
//     return ele.year;
//   });
//   let statusRate;
//   Object.keys(newResult).forEach((ele) => {
//     for (let i = 0; i < newResult[ele].length; i++) {
//       statusRate = Math.floor(
//         (newResult[ele][i]['status'].split('出').length - 1) * 6.67
//       );
//       newResult[ele][i]['status_rate'] = statusRate;
//     }
//   });
//   console.log(newResult);
//   const MyButton = styled(Button)(({ theme }) => ({
//     ...theme.typography.miniButton,
//     height: '4rem',
//     backgroundColor: 'orange',
//     margin: 'auto',
//     textAlign: 'center',
//     width: '6rem',
//     padding: '0px',
//     '&:hover': {
//       // backgroundColor: 'white',
//       opacity: 0.7,
//     },
//     [theme.breakpoints.down('md')]: {
//       height: '6rem',
//       width: '20rem',
//     },
//     [theme.breakpoints.down('sm')]: {
//       fontSize: '0.8rem',
//       height: '4rem',
//       width: '15rem',
//     },
//   }));
//   const MyTab = styled(Tab)(({ theme }) => ({
//     ...theme.typography.tab,
//     borderRadius: '30px',
//     margin: 'auto',
//     // console.log(theme);
//     backgroundColor: theme.palette.primary.main,
//   }));

//   console.log(idStatus);
//   const TabBeforePanel = (props) => {
//     //TabPanelのタブを作る関数
//     const { children, value, index, year } = props; //引数を入れる
//     return (
//       <div>
//         {index === value && ( //valueと設定している値(index)が同じ値のタブを入れる．
//           //  ↓TabPanelで囲まれた部分(本文)を表示する．
//           <Box sx={{ pt: 3, pl: 2 }}>
//             <ClickAwayListener onClickAway={() => handleExit()}>
//               <TableContainer sx={{ mariginTop: '100px' }}>
//                 <Table>
//                   <TableHead>
//                     {year}年度前期
//                     <TableRow>
//                       <TableCell>授業名</TableCell>
//                       <TableCell>学年</TableCell>
//                       <TableCell>出席率</TableCell>
//                       <TableCell>第1回</TableCell>
//                       <TableCell>第2回</TableCell>
//                       <TableCell>第3回</TableCell>
//                       <TableCell>第4回</TableCell>
//                       <TableCell>第5回</TableCell>
//                       <TableCell>第6回</TableCell>
//                       <TableCell>第7回</TableCell>
//                       <TableCell>第8回</TableCell>
//                       <TableCell>第9回</TableCell>
//                       <TableCell>第10回</TableCell>
//                       <TableCell>第11回</TableCell>
//                       <TableCell>第12回</TableCell>
//                       <TableCell>第13回</TableCell>
//                       <TableCell>第14回</TableCell>
//                       <TableCell>第15回</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   {children}
//                 </Table>
//               </TableContainer>
//             </ClickAwayListener>
//           </Box>
//         )}
//       </div>
//     );
//   };
//   const TabAfterPanel = (props) => {
//     //TabPanelのタブを作る関数
//     const { children, value, index, year } = props; //引数を入れる
//     return (
//       <div>
//         {index === value && ( //valueと設定している値(index)が同じ値のタブを入れる．
//           //  ↓TabPanelで囲まれた部分(本文)を表示する．
//           <Box sx={{ pt: 3, pl: 2 }}>
//             <ClickAwayListener onClickAway={() => handleExit()}>
//               <TableContainer sx={{ mariginTop: '100px' }}>
//                 <Table>
//                   <TableHead>
//                     {year}年度後期
//                     <TableRow>
//                       <TableCell>授業名</TableCell>
//                       <TableCell>学年</TableCell>
//                       <TableCell>出席率</TableCell>
//                       <TableCell>第1回</TableCell>
//                       <TableCell>第2回</TableCell>
//                       <TableCell>第3回</TableCell>
//                       <TableCell>第4回</TableCell>
//                       <TableCell>第5回</TableCell>
//                       <TableCell>第6回</TableCell>
//                       <TableCell>第7回</TableCell>
//                       <TableCell>第8回</TableCell>
//                       <TableCell>第9回</TableCell>
//                       <TableCell>第10回</TableCell>
//                       <TableCell>第11回</TableCell>
//                       <TableCell>第12回</TableCell>
//                       <TableCell>第13回</TableCell>
//                       <TableCell>第14回</TableCell>
//                       <TableCell>第15回</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   {children}
//                 </Table>
//               </TableContainer>
//             </ClickAwayListener>
//           </Box>
//         )}
//       </div>
//     );
//   };
//   const handleChange = (e, id) => {
//     // console.log(id);
//     const result = idStatus.find((element) => {
//       return element.id === id;
//     });
//     if (!result) {
//       setIdStatus([...idStatus, { id: id, status: e.target.value }]);
//     } else {
//       setIdStatus((prevState) =>
//         prevState.map((obj) =>
//           obj.id === id ? { id: obj.id, status: e.target.value } : obj
//         )
//       );
//     }
//     const changedResult = changedStatus.find((element) => {
//       return element.id === id;
//     });
//     //最初の一回・同じidが定義されていない時
//     if (!changedStatus.length || changedResult === undefined) {
//       setChangedStatus([...changedStatus, { id: id, status: e.target.value }]);
//     } else {
//       setChangedStatus((prevState) =>
//         prevState.map((obj) =>
//           obj.id === id ? { id: obj.id, status: e.target.value } : obj
//         )
//       );
//     }
//   };
//   const handleExit = () => {
//     setRowIndex(-1);
//     setColumnIndex(-1);
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log(changedStatus);
//     const res = await axios.put('/api/attendance/user', changedStatus);
//     console.log(res);
//   };
//   const handleTabChange = (event, newValue) => {
//     setValue(newValue);
//   };
//   return (
//     <>
//       <Tabs
//         value={value}
//         onChange={handleTabChange}
//         TabIndicatorProps={{
//           sx: { transition: 'none' },
//         }}
//         sx={{ marginTop: '40px' }}
//         textColor='inherit'
//       >
//         {Object.keys(newResult).map((ele, index) => {
//           return (
//             <MyTab
//               key={index}
//               label={`${ele}年度`}
//               id={`simple-tab-${index}`}
//               aria-controls={`simple-tabpanel-${index}`}
//             />
//           );
//         })}
//       </Tabs>

//       {Object.keys(newResult).map((ele, index) => {
//         newResult[ele].sort((a, b) => a.term - b.term);
//         return (
//           <>
//             <TabBeforePanel key={index} value={value} index={index} year={ele}>
//               {newResult[ele].map((list, index) =>
//                 list.term === 0 ? (
//                   <>
//                     <TableBody>
//                       <TableRow key={index}>
//                         <TableCell>{list.name}</TableCell>
//                         <TableCell>{list.grade}</TableCell>
//                         <TableCell>{list.status_rate}%</TableCell>
//                         {[...list.status].map((e, index) => (
//                           <TableCell
//                             key={`status_${index}`}
//                             onClick={() => {
//                               setRowIndex(index);
//                               setColumnIndex(0);
//                             }}
//                           >
//                             {rowIndex === index && columnIndex === 0 ? (
//                               <TextField
//                                 id={`status_field_${index}`}
//                                 defaultValue={idStatus[index]['status']}
//                                 // value={idStatus[index]['status']}
//                                 onBlur={(e) => {
//                                   handleChange(e, list.id);
//                                 }}
//                                 onKeyPress={(e) => {
//                                   if (e.key === 'Enter') {
//                                     handleExit();
//                                   }
//                                 }}
//                               />
//                             ) : (
//                               idStatus[index]['status']
//                             )}
//                           </TableCell>
//                         ))}
//                       </TableRow>
//                     </TableBody>
//                   </>
//                 ) : (
//                   <></>
//                 )
//               )}
//             </TabBeforePanel>
//             <TabAfterPanel key={index} value={value} index={index} year={ele}>
//               {newResult[ele].map((list, index) =>
//                 list.term === 1 ? (
//                   <>
//                     <TableBody>
//                       <TableRow key={index}>
//                         <TableCell>{list.name}</TableCell>
//                         <TableCell>{list.grade}</TableCell>
//                         <TableCell>{list.status_rate}%</TableCell>
//                         {[...list.status].map((element, index) => (
//                           <TableCell
//                             key={`status_${index}`}
//                             onClick={() => {
//                               setRowIndex(index);
//                               setColumnIndex(0);
//                             }}
//                           >
//                             {rowIndex === index && columnIndex === 0 ? (
//                               <TextField
//                                 id={`status_field2_${index}`}
//                                 defaultValue={idStatus[index]['status']}
//                                 // value={idStatus[index]['status']}
//                                 onBlur={(e) => {
//                                   handleChange(e, list.id);
//                                 }}
//                                 onKeyPress={(e) => {
//                                   if (e.key === 'Enter') {
//                                     handleExit();
//                                   }
//                                 }}
//                               />
//                             ) : (
//                               idStatus[index]['status']
//                             )}
//                           </TableCell>
//                         ))}
//                       </TableRow>
//                     </TableBody>
//                   </>
//                 ) : (
//                   <></>
//                 )
//               )}
//             </TabAfterPanel>
//             <MyButton
//               onClick={(e) => {
//                 handleSubmit(e);
//               }}
//               // component={Link}
//               // to='/dashboard'
//             >
//               更新
//             </MyButton>
//           </>
//         );
//       })}
//     </>
//   );
// };
// export default AttendanceChart;

import { React, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';



const AttendanceChart = ({ attendanceData }) => {
  let attendances = JSON.parse(JSON.stringify(attendanceData)); //表示用に使用
  // let editAttendances = JSON.parse(JSON.stringify(attendanceData)); //更新用に使用
  //表全体の状態を管理

  // const [rowIndex, setRowIndex] = useState(-1);
  // const [columnIndex, setColumnIndex] = useState(-1);
  // //変更された状態を管理
  // const [changedStatus, setChangedStatus] = useState([]);

  const [value, setValue] = useState(0);




  /**
   * term,grade,year,授業名が一致する，statusをまとめている．(表示するために．)
   */
  let result = [];
  let totalStatus = '';
  for (let i = 0; i < attendances.length - 1; i++) {
    totalStatus = attendances[i].status;
    for (let j = i + 1; j < attendances.length; j++) {
      if (
        attendances[i].term === attendances[j].term &&
        attendances[i].grade === attendances[j].grade &&
        attendances[i].year === attendances[j].year &&
        attendances[i].name === attendances[j].name
      ) {
        totalStatus += attendances[j].status;
        attendances[j].status = '';
        // attendances.splice(j, 1);
      }
    }
    if (totalStatus) {
      result.push({
        ...attendances[i],
        status: totalStatus,
      });
    }
  }

  // const newResult = _.groupBy(editAttendances, (ele) => {
  //   return ele.year;
  // });
  const newResult = _.groupBy(result, (ele) => {
    return ele.year;
  });
  let statusRate;
  Object.keys(newResult).forEach((ele) => {
    for (let i = 0; i < newResult[ele].length; i++) {
      statusRate = Math.floor(
        (newResult[ele][i]['status'].split('出').length - 1) * 6.67
      );
      newResult[ele][i]['status_rate'] = statusRate;
    }
  });



  const MyTab = styled(Tab)(({ theme }) => ({
    ...theme.typography.tab,
    borderRadius: '30px',
    margin: 'auto',
    // console.log(theme);
    backgroundColor: theme.palette.primary.main,
  }));

  const TabBeforePanel = (props) => {
    //TabPanelのタブを作る関数
    const { children, value, index, year } = props; //引数を入れる
    return (
      <div>
        {index === value && ( //valueと設定している値(index)が同じ値のタブを入れる．
          //  ↓TabPanelで囲まれた部分(本文)を表示する．
          <Box sx={{ pt: 3, pl: 2 }}>
              <TableContainer sx={{ mariginTop: '100px' }}>
                <Table>
                  <TableHead>
                    {year}年度前期
                    <TableRow>
                      <TableCell>授業名</TableCell>
                      <TableCell>学年</TableCell>
                      <TableCell>出席率</TableCell>
                      <TableCell>第1回</TableCell>
                      <TableCell>第2回</TableCell>
                      <TableCell>第3回</TableCell>
                      <TableCell>第4回</TableCell>
                      <TableCell>第5回</TableCell>
                      <TableCell>第6回</TableCell>
                      <TableCell>第7回</TableCell>
                      <TableCell>第8回</TableCell>
                      <TableCell>第9回</TableCell>
                      <TableCell>第10回</TableCell>
                      <TableCell>第11回</TableCell>
                      <TableCell>第12回</TableCell>
                      <TableCell>第13回</TableCell>
                      <TableCell>第14回</TableCell>
                      <TableCell>第15回</TableCell>
                    </TableRow>
                  </TableHead>
                  {children}
                </Table>
              </TableContainer>
          </Box>
        )}
      </div>
    );
  };
  const TabAfterPanel = (props) => {
    //TabPanelのタブを作る関数
    const { children, value, index, year } = props; //引数を入れる
    return (
      <div>
        {index === value && ( //valueと設定している値(index)が同じ値のタブを入れる．
          //  ↓TabPanelで囲まれた部分(本文)を表示する．
          <Box sx={{ pt: 3, pl: 2 }}>
              <TableContainer sx={{ mariginTop: '100px' }}>
                <Table>
                  <TableHead>
                    {year}年度後期
                    <TableRow>
                      <TableCell>授業名</TableCell>
                      <TableCell>学年</TableCell>
                      <TableCell>出席率</TableCell>
                      <TableCell>第1回</TableCell>
                      <TableCell>第2回</TableCell>
                      <TableCell>第3回</TableCell>
                      <TableCell>第4回</TableCell>
                      <TableCell>第5回</TableCell>
                      <TableCell>第6回</TableCell>
                      <TableCell>第7回</TableCell>
                      <TableCell>第8回</TableCell>
                      <TableCell>第9回</TableCell>
                      <TableCell>第10回</TableCell>
                      <TableCell>第11回</TableCell>
                      <TableCell>第12回</TableCell>
                      <TableCell>第13回</TableCell>
                      <TableCell>第14回</TableCell>
                      <TableCell>第15回</TableCell>
                    </TableRow>
                  </TableHead>
                  {children}
                </Table>
              </TableContainer>
          </Box>
        )}
      </div>
    );
  };


  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs
        value={value}
        onChange={handleTabChange}
        TabIndicatorProps={{
          sx: { transition: 'none' },
        }}
        sx={{ marginTop: '40px' }}
        textColor='inherit'
      >
        {Object.keys(newResult).map((ele, index) => {
          return (
            <MyTab
              key={index}
              label={`${ele}年度`}
              id={`simple-tab-${index}`}
              aria-controls={`simple-tabpanel-${index}`}
            />
          );
        })}
      </Tabs>

      {Object.keys(newResult).map((ele, index) => {
        newResult[ele].sort((a, b) => a.term - b.term);
        return (
          <>
            <TabBeforePanel key={index} value={value} index={index} year={ele}>
              {newResult[ele].map((list, index) =>
                list.term === 0 ? (
                  <>
                    <TableBody>
                      <TableRow key={index}>
                        <TableCell>{list.name}</TableCell>
                        <TableCell>{list.grade}</TableCell>
                        <TableCell>{list.status_rate}%</TableCell>
                        {[...list.status].map((e, index) => (
                          <TableCell
                            key={`status_${index}`}
                          >
                           {e}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  <></>
                )
              )}
            </TabBeforePanel>
            <TabAfterPanel key={index} value={value} index={index} year={ele}>
              {newResult[ele].map((list, index) =>
                list.term === 1 ? (
                  <>
                    <TableBody>
                      <TableRow key={index}>
                        <TableCell>{list.name}</TableCell>
                        <TableCell>{list.grade}</TableCell>
                        <TableCell>{list.status_rate}%</TableCell>
                        {[...list.status].map((element, index) => (
                          <TableCell
                            key={`status_${index}`}
                          >
                           {element}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  <></>
                )
              )}
            </TabAfterPanel>

          </>
        );
      })}
    </>
  );
};
export default AttendanceChart;
