import React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// import { Link } from 'react-router-dom';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
// import axios from 'axios';

const AdminScoreChart = ({ scoreData }) => {
  const [value, setValue] = useState(0);
  //どのコンポを保存しておくかを決めているuseState
  //変更された状態を管理

  const test = _.groupBy(scoreData, (ele) => {
    return ele.year;
  });
  // const idStatusData = scoreData.map((ele) => {
  //   return { id: ele.id, score: ele.score };
  // });

  const array = [];
  //scoreDataから更新するのに必要のないid,score,year以外を削除している．
  Object.keys(test).forEach((ele) => {

    for (let i = 0; i < test[ele].length; i++) {
      array.push({
        id: test[ele][i]['id'],
        score: test[ele][i]['score'],
        year: test[ele][i]['year'],
      });
    }
  });
  const idStatusData = _.groupBy(array, (ele) => {
    return ele.year;
  });
  let result = [...scoreData];


  const newResult = _.groupBy(result, (ele) => {
    return ele.year;
  });


  const [idStatus, setIdStatus] = useState(idStatusData);
  const [changedStatus, setChangedStatus] = useState([]);
  const [rowIndex, setRowIndex] = useState(-1);
  const [columnIndex, setColumnIndex] = useState(-1);

  // const allData = scoreData;
  // const result = scoreData;

  // console.log(result);

  const MyTab = styled(Tab)(({ theme }) => ({
    ...theme.typography.tab,
    borderRadius: '30px',
    margin: 'auto',
    // console.log(theme);
    backgroundColor: theme.palette.primary.main,
  }));
  const MyButton = styled(Button)(({ theme }) => ({
    ...theme.typography.miniButton,
    height: '4rem',
    backgroundColor: 'orange',
    margin: 'auto',
    textAlign: 'center',
    width: '6rem',
    padding: '0px',
    '&:hover': {
      // backgroundColor: 'white',
      opacity: 0.7,
    },
    [theme.breakpoints.down('md')]: {
      height: '6rem',
      width: '20rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      height: '4rem',
      width: '15rem',
    },
  }));
  const TabBeforePanel = (props) => {
    //TabPanelのタブを作る関数
    const { children, value, index, year } = props; //引数を入れる
    return (
      <div>
        {index === value && ( //valueと設定している値(index)が同じ値のタブを入れる．
          //  ↓TabPanelで囲まれた部分(本文)を表示する．
          <Box sx={{ pt: 3, pl: 2 }}>
            <Grid item xs={12}>
              <TableContainer sx={{ mariginTop: '100px' }}>
                <Table>
                  <TableHead>
                    {year}年度前期
                    <TableRow>
                      <TableCell>授業名</TableCell>
                      <TableCell>素点</TableCell>
                    </TableRow>
                  </TableHead>
                  {children}
                </Table>
              </TableContainer>
            </Grid>
          </Box>
        )}
      </div>
    );
  };
  const TabAfterPanel = (props) => {
    //TabPanelのタブを作る関数
    const { children, value, index, year } = props; //引数を入れる
    return (
      <div>
        {index === value && ( //valueと設定している値(index)が同じ値のタブを入れる．
          //  ↓TabPanelで囲まれた部分(本文)を表示する．
          <Box sx={{ pt: 3, pl: 2 }}>
            <Grid item xs={12}>
              <TableContainer sx={{ mariginTop: '100px' }}>
                <Table>
                  <TableHead>
                    {year}年度後期
                    <TableRow>
                      <TableCell>授業名</TableCell>
                      <TableCell>素点</TableCell>
                    </TableRow>
                  </TableHead>
                  {children}
                </Table>
              </TableContainer>
            </Grid>
          </Box>
        )}
      </div>
    );
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (e, id, year) => {
    const changedNum = Number(e.target.value);
    const result = idStatus[year].find((element) => {
      return element.id === id;
    });
    if (!result) {
      setIdStatus([...idStatus, { id: id, score: changedNum }]);
    } else {
      //ここ修正する！
      setIdStatus(
        (prevState) => {
          _.forEach(prevState, (value) => {
            value.forEach((obj) => {
              if (obj.id === id) {
                _.assign(obj, {
                  id: obj.id,
                  score: changedNum,
                  year: year,
                });
              }
            });
          });
          return prevState;
        }
        //   return prevSstate
        // // })
        // _.map(prevState, obj =>
        //   obj.id === id
        //     ? { id: obj.id, score: changedNum, year: year }
        //     : obj)
        // )
      );
    }

    const changedResult = changedStatus.find((element) => {
      return element.id === id;
    });
    //最初の一回・同じidが定義されていない時
    if (!changedStatus.length || changedResult === undefined) {
      setChangedStatus([...changedStatus, { id: id, score: changedNum }]);
    } else {
      setChangedStatus((prevState) =>
        prevState.map((obj) =>
          obj.id === id ? { id: obj.id, score: changedNum } : obj
        )
      );
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // const res = await axios.put('/api/score/user', changedStatus);

  };


  return (
    <>
      <Grid
        container
        spacing={5}
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ marginTop: '10px' }}
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          TabIndicatorProps={{
            sx: { transition: 'none' },
          }}
          sx={{ marginTop: '40px' }}
          textColor='inherit'
        >
          {Object.keys(newResult).map((ele, index) => {
            return (
              <MyTab
                key={index}
                label={`${ele}年度`}
                id={`simple-tab-${index}`}
                aria-controls={`simple-tabpanel-${index}`}
              />
            );
          })}
        </Tabs>

        {Object.keys(newResult).map((ele, index) => {
          newResult[ele].sort((a, b) => a.term - b.term);
          console.log(newResult);
          return (
            <>
              <TabBeforePanel
                key={index}
                value={value}
                index={index}
                year={ele}
              >
                {newResult[ele].map((list, index) =>
                  list.term === 0 ? (
                    <>
                      <TableBody>
                        <TableRow key={index}>
                          <TableCell>{list.class_name}</TableCell>
                          <TableCell
                            onClick={() => {
                              setRowIndex(index);
                              setColumnIndex(0);
                            }}
                          >
                            {rowIndex === index && columnIndex === 0 ? (
                              <TextField
                                id={`zenki_${index}`}
                                defaultValue={idStatus[ele][index]['score']}
                                // value={idStatus[index]['status']}
                                onBlur={(e) => {
                                  handleChange(e, list.id, ele);
                                }}
                                // onKeyPress={(e) => {
                                //   if (e.key === 'Enter') {
                                //     handleExit();
                                //   }
                                // }}
                              />
                            ) : (
                              idStatus[ele][index]['score']
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </>
                  ) : (
                    <></>
                  )
                )}
              </TabBeforePanel>
              <TabAfterPanel key={index} value={value} index={index} year={ele}>
                {newResult[ele].map((list, index) =>
                  list.term === 1 ? (
                    <>
                      <TableBody>
                        <TableRow key={`kouki_${index}`}>
                          <TableCell>{list.class_name}</TableCell>
                          <TableCell
                            onClick={() => {
                              setRowIndex(index);
                              setColumnIndex(0);
                            }}
                          >
                            {rowIndex === index && columnIndex === 0 ? (
                              <TextField
                                id={index}
                                defaultValue={idStatus[ele][index]['score']}
                                // value={idStatus[index]['status']}
                                onBlur={(e) => {
                                  handleChange(e, list.id, ele);
                                }}
                                // onKeyPress={(e) => {
                                //   if (e.key === 'Enter') {
                                //     handleExit();
                                //   }
                                // }}
                                key={`kouki_${index}`}
                              />
                            ) : (
                              idStatus[ele][index]['score']
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </>
                  ) : (
                    <></>
                  )
                )}
              </TabAfterPanel>
            </>
          );
        })}
        <Grid item xs={12}>
          <MyButton
            onClick={(e) => {
              handleSubmit(e);
            }}
            // component={Link}
            // to='/dashboard'
          >
            更新
          </MyButton>
        </Grid>
      </Grid>
    </>
  );
};
export default AdminScoreChart;
