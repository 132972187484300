import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  score: null,
  error: {},
};

const scoreSlice = createSlice({
  name: 'score',
  initialState,
  reducers: {
    scoreSuccess(state, action) {
      return {
        ...state,
        score: action.payload,
      };
    },
    scoreError(state, action) {
      return {
        ...state,
        error: action.payload,
      };
    },
    scoreClear(state) {
      return {
        ...state,
        score: null,
      };
    },
  },
});

export const scoreActions = scoreSlice.actions;
export default scoreSlice.reducer;
