import React from 'react';
// import { loadUser } from '../../actions/auth';
// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// import { getAdminAttendance } from '../../actions/attendance';
import Spinner from '../../utils/Spiner';
import AdminAttendanceChart from './AdminAttendanceChart';
// import AdminAttendancePreChart from './AdminAttendancePreChart';
import { useParams } from 'react-router-dom';
import axios from 'axios';
const AdminAttendance = () => {
  const params = useParams();
  const [attendanceState, setAttendanceState] = useState(null);
  // const attendanceState = useSelector((state) => {
  //   return state.attendance.attendances;
  // });
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/api/attendance/user/${params.stid}/`);
      if (!res) {
        throw new Error('データがない');
      }
      setAttendanceState(res.data);
    };
    fetchData();
  }, []);
  return attendanceState === null ? (
    <Spinner />
  ) : (
    <>
      <AdminAttendanceChart attendanceData={attendanceState} />
    </>
  );
};

export default AdminAttendance;
