import { configureStore } from '@reduxjs/toolkit';

import authAdminReducer from './authAdmin';
import authReducer from './auth';
import attendanceReducer from './attendance';
import scoreReducer from './score';
//このstoreはおそらく，indexjsなどでstoreを使える際に必要なため作成している．
export const store = configureStore({
  // reducer: authSlice.reducer,
  reducer: {
    auth: authReducer,
    authAdmin: authAdminReducer,
    attendance: attendanceReducer,
    score: scoreReducer,
  },
});
