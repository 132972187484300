import { React, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Grid from '@mui/material/Grid';
const AttendanceChart = ({ attendanceData }) => {
  let attendances = JSON.parse(JSON.stringify(attendanceData));
  const [value, setValue] = useState(0);
  attendances = [...attendances];
  //どのコンポを保存しておくかを決めているuseState
  // 重複を取り除く処理

  let result = [];
  let totalStatus = '';
  //MAXの授業回数である60回のListを生成してる．

  const numbers = Array.from({ length: 60 }, (_, index) => index + 1);

  for (let i = 0; i < attendances.length - 1; i++) {
    totalStatus = attendances[i].status;
    for (let j = i + 1; j < attendances.length; j++) {
      if (
        attendances[i].term === attendances[j].term &&
        attendances[i].grade === attendances[j].grade &&
        attendances[i].year === attendances[j].year &&
        attendances[i].name === attendances[j].name
      ) {
        totalStatus += attendances[j].status;
        attendances[j].status = '';
        // attendances.splice(j, 1);
      }
    }
    if (totalStatus) {
      result.push({
        ...attendances[i],
        status: totalStatus,
      });
    }
  }
  // console.log(result);

  const newResult = _.groupBy(result, (ele) => {
    return ele.year;
  });
  let statusRate; //出席率

  Object.keys(newResult).forEach((ele) => {
 

    for (let i = 0; i < newResult[ele].length; i++) {
      const perScore = 100 / newResult[ele][i].status.length;
      statusRate = Math.floor(
        (newResult[ele][i]['status'].split('出').length - 1) * perScore
      );
      // if (statusRate > 100) {
      //   statusRate = 100;
      // }
      newResult[ele][i]['status_rate'] = statusRate;
    }
  });
  // console.log(newResult);

  const MyTab = styled(Tab)(({ theme }) => ({
    ...theme.typography.tab,
    borderRadius: '30px',
    margin: 'auto',
    // console.log(theme);
    backgroundColor: theme.palette.primary.main,
  }));
  const TabBeforePanel = (props) => {
    //TabPanelのタブを作る関数
    const { children, value, index, year } = props; //引数を入れる
    return (
      <div>
        {index === value && ( //valueと設定している値(index)が同じ値のタブを入れる．
          //  ↓TabPanelで囲まれた部分(本文)を表示する．
          <Box sx={{ overflow: 'auto' }}>
            <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
              <Table>
                <TableHead>
                  {year}年度前期
                  <TableRow>
                    <TableCell>授業名</TableCell>
                    <TableCell>学年</TableCell>
                    <TableCell>出席率</TableCell>
                    {numbers.map((number) => (
                      <TableCell key={number}> 第{number} 回</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {children}
              </Table>
            </Box>
          </Box>
        )}
      </div>
    );
  };
  const TabAfterPanel = (props) => {
    //TabPanelのタブを作る関数
    const { children, value, index, year } = props; //引数を入れる
    return (
      <div>
        {index === value && ( //valueと設定している値(index)が同じ値のタブを入れる．
          //  ↓TabPanelで囲まれた部分(本文)を表示する．
          <Box sx={{ pt: 3, pl: 2 }}>
            <TableContainer sx={{ mariginTop: '100px' }}>
              <Table>
                <TableHead>
                  {year}年度後期
                  <TableRow>
                    <TableCell>授業名</TableCell>
                    <TableCell>学年</TableCell>
                    <TableCell>出席率</TableCell>
                    {numbers.map((number) => {
                      <TableCell key={number}> 第{number} 回</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                {children}
              </Table>
            </TableContainer>
          </Box>
        )}
      </div>
    );
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          sx: { transition: 'none' },
        }}
        sx={{ marginTop: '100px' }}
        textColor='inherit'
      >
        {Object.keys(newResult).map((ele, index) => {
          return (
            <MyTab
              key={index}
              label={`${ele}年度`}
              id={`simple-tab-${index}`}
              aria-controls={`simple-tabpanel-${index}`}
            />
          );
        })}
      </Tabs>

      {Object.keys(newResult).map((ele, index) => {
        newResult[ele].sort((a, b) => a.term - b.term);
        return (
          <>
            <TabBeforePanel key={index} value={value} index={index} year={ele}>
              {newResult[ele].map((list, index) =>
                list.term === 0 ? (
                  <>
                    <TableBody>
                      <TableRow key={index}>
                        <TableCell>{list.name}</TableCell>
                        <TableCell>{list.grade}</TableCell>
                        <TableCell>{list.status_rate}%</TableCell>
                        {[...list.status].map((e, index) => (
                          <TableCell key={`status_${index}`}>{e}</TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  <></>
                )
              )}
            </TabBeforePanel>

            <TabAfterPanel key={index} value={value} index={index} year={ele}>
              {newResult[ele].map((list, index) =>
                list.term === 1 ? (
                  <>
                    <TableBody>
                      <TableRow key={index}>
                        <TableCell>{list.name}</TableCell>
                        <TableCell>{list.grade}</TableCell>
                        <TableCell>{list.status_rate}%</TableCell>
                        {[...list.status].map((e, index) => (
                          <TableCell key={`status_${index}`}>{e}</TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  <></>
                )
              )}
            </TabAfterPanel>
          </>
        );
      })}
    </>
  );
};
export default AttendanceChart;
