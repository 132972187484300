import { createTheme } from '@mui/material/styles';

const akBlue = '#0093E9';
const akOrange = '#F8AD18';

const theme = createTheme({
  palette: {
    common: {
      blue: `${akBlue}`,
      orange: `${akOrange}`,
    },
    primary: {
      main: `${akBlue}`,
    },
    secondary: {
      main: `${akOrange}`,
    },
  },
  typography: {
    tab: {
      fontFamily: 'Railway',
      textTransform: 'none',
      fontWeight: '700',
      fontSize: '1rem',
      color: 'white',
    },
    estimate: {
      fontFamily: 'Pacifico',
      fontSize: '1rem',
      color: 'white',
    },
    form: {
      fontFamily: 'Pacifico',
      fontSize: '1rem',
      color: 'white',
    },
    basic: {
      fontFamily: 'Pacifico',
      fontSize: '2rem',
      color: 'white',
    },
    miniButton: {
      fontFamily: 'Pacifico',
      fontSize: '0.9rem',
      color: 'black',
      fontWeight: '700',
    },
  },
});

export default theme;
