import React from 'react';
import { useEffect } from 'react';
import Header from './ui/Header';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './ui/Theme';
// import Register from './auth/Register';
import Login from './auth/Login';
import AdminLogin from './auth/AdminLogin';
import setAuthToken from '../utils/setAuthToken';
import { loadUser } from '../actions/auth';
import Attendance from '../components/student/Attendance';
import DashBoard from './admin/DashBoard';
import { loadAdminUser } from '../actions/authAdmin';
import { useDispatch } from 'react-redux';
import Score from '../components/student/Score';
import PrivateRoute from './routing/PrivateRoute';
import PrivateAdminRoute from './routing/PrivateAdminRoute';
import AdminAttendance from '../components/admin/AdminAttendance';
import AdminAttendanceEdit from '../components/admin/AdminAttendanceEdit';
import AdminAttendanceCreate from '../components/admin/AdminAttendanceCreate';
import AdminScore from '../components/admin/AdminScore';
import StudentInfo from '../components/student/StudentInfo';

// import { store } from './store/index';
//ローカルストレージにtokenがあれば，ヘッダーにセット．
if (localStorage.token) {
  setAuthToken(localStorage.token);
}
function App() {
  const dispatch = useDispatch();
  //管理者ログイン判定
  // const state = useSelector((state) => {
  //   return state.authAdmin.isAuthenticated;
  // });
  useEffect(() => {
    if (localStorage.token) {
      loadUser(dispatch);
      loadAdminUser(dispatch);
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/admin/login' element={<AdminLogin />} />

          <Route path='/score' element={<PrivateRoute />}>
            <Route path='/score' element={<Score />} />
          </Route>
          <Route path='/attendance' element={<PrivateRoute />}>
            <Route path='/attendance' element={<Attendance />} />
          </Route>

          {/* <Route path='/admin/register' element={<PrivateAdminRoute />}>
            <Route path='/admin/register' element={<Register />} />
          </Route> */}

          <Route path='/dashboard' element={<PrivateAdminRoute />}>
            <Route path='/dashboard' element={<DashBoard />} />
            <Route
              path='/dashboard/attendance/:stid'
              element={<AdminAttendance />}
            />
            <Route
              path='/dashboard/attendance/edit/:stid'
              element={<AdminAttendanceEdit />}
            />
            <Route
              path='/dashboard/attendance/create/:stid'
              element={<AdminAttendanceCreate />}
            />
            <Route path='/dashboard/score/:stid' element={<AdminScore />} />
          </Route>
          <Route path='/info' element={<PrivateRoute />}>
            <Route path='/info' element={<StudentInfo />} />
          </Route>
          <Route path='/grade' element={<PrivateRoute />}>
            <Route path='/grade' element={'成績情報'} />
          </Route>
          <Route path='/syllabus' element={<PrivateRoute />}>
            <Route path='/syllabus' element={'シラバス'} />
          </Route>
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
