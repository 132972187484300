import axios from 'axios';
import { authActions } from '../../src/store/auth';

import setAuthToken from '../utils/setAuthToken';
import { attendanceActions } from '../store/attendance';

/**
 *生徒がログインする時に，dispatchする
 * @param {*} user_id ユーザid
 * @param {*} password パスワード
 * @param {*} dispatch useDispatch
 */
export const login = async (user_id, password, dispatch) => {
  const header = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    user_id,
    password,
  });
  try {
    const res = await axios.post('/api/auth/login', body, header);
    dispatch(authActions.loginSuccess(res.data));
    loadUser(dispatch);
  } catch (err) {
    dispatch(authActions.loginFail());
  }
};

/**
 * ローカルストレージのtokenをヘッダーにいれて，user情報を取得し，reduxのstoreに格納している
 * @param {*} dispatch useDispatch
 */
export const loadUser = async (dispatch) => {
  //ローカルストレージにtokenがあれば，ヘッダーにセット．
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  // console.log(localStorage.token);
  try {
    const res = await axios.get('/api/auth');
    dispatch(authActions.userLoad(res.data));
  } catch (err) {
    // console.error(err.message);
    dispatch(authActions.authError());
  }
};

/**
 *ログアウト処理
 * @param {*} dispatch useDispatch
 */
export const logout = (dispatch) => {
  dispatch(authActions.logout());
  dispatch(attendanceActions.attendanceClear());
};
