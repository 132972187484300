import React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import { Link } from 'react-router-dom';
import _ from 'lodash';
import { styled } from '@mui/material/styles';

const ScoreChart = ({ scoreData }) => {
  const [value, setValue] = useState(0);
  //どのコンポを保存しておくかを決めているuseState

  const MyTab = styled(Tab)(({ theme }) => ({
    ...theme.typography.tab,
    borderRadius: '30px',
    margin: 'auto',
    // console.log(theme);
    backgroundColor: theme.palette.primary.main,
  }));

  let result = [...scoreData];

  // const allData = scoreData;
  // const result = scoreData;
  const dammyData = [...result];

  const newResult = _.groupBy(dammyData, (ele) => {
    return ele.year;
  });
  // console.log(newResult);
  // console.log(result);

  const TabBeforePanel = (props) => {
    //TabPanelのタブを作る関数
    const { children, value, index, year } = props; //引数を入れる
    return (
      <div>
        {index === value && ( //valueと設定している値(index)が同じ値のタブを入れる．
          //  ↓TabPanelで囲まれた部分(本文)を表示する．
          <Box sx={{ pt: 3, pl: 2 }}>
            <TableContainer sx={{ mariginTop: '100px' }}>
              <Table>
                <TableHead>
                  {year}年度前期
                  <TableRow>
                    <TableCell>授業名</TableCell>
                    <TableCell>素点</TableCell>
                  </TableRow>
                </TableHead>
                {children}
              </Table>
            </TableContainer>
          </Box>
        )}
      </div>
    );
  };
  const TabAfterPanel = (props) => {
    //TabPanelのタブを作る関数
    const { children, value, index, year } = props; //引数を入れる
    return (
      <div>
        {index === value && ( //valueと設定している値(index)が同じ値のタブを入れる．
          //  ↓TabPanelで囲まれた部分(本文)を表示する．
          <Box sx={{ pt: 3, pl: 2 }}>
            <TableContainer sx={{ mariginTop: '100px' }}>
              <Table>
                <TableHead>
                  {year}年度後期
                  <TableRow>
                    <TableCell>授業名</TableCell>
                    <TableCell>素点</TableCell>
                  </TableRow>
                </TableHead>
                {children}
              </Table>
            </TableContainer>
          </Box>
        )}
      </div>
    );
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          sx: { transition: 'none' },
        }}
        sx={{ marginTop: '40px' }}
        textColor='inherit'
      >
        {Object.keys(newResult).map((ele, index) => {
          return (
            <MyTab
              key={index}
              label={`${ele}年度`}
              id={`simple-tab-${index}`}
              aria-controls={`simple-tabpanel-${index}`}
            />
          );
        })}
      </Tabs>

      {Object.keys(newResult).map((ele, index) => {
        newResult[ele].sort((a, b) => a.term - b.term);
        return (
          <>
            <TabBeforePanel key={index} value={value} index={index} year={ele}>
              {newResult[ele].map((list, index) =>
                list.term === 0 ? (
                  <>
                    <TableBody>
                      <TableRow key={index}>
                        <TableCell>{list.class_name}</TableCell>
                        <TableCell>{list.score}</TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  <></>
                )
              )}
            </TabBeforePanel>
            <TabAfterPanel key={index} value={value} index={index} year={ele}>
              {newResult[ele].map((list, index) =>
                list.term === 1 ? (
                  <>
                    <TableBody>
                      <TableRow key={index}>
                        <TableCell>{list.class_name}</TableCell>
                        <TableCell>{list.score}</TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  <></>
                )
              )}
            </TabAfterPanel>
          </>
        );
      })}
    </>
  );
};
export default ScoreChart;
