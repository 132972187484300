import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  attendance: null,
  attendances: [],
  error: {},
};

const attendanceSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    attendanceSuccess(state, action) {
      return {
        ...state,
        attendance: action.payload,
      };
    },
    attendanceError(state, action) {
      return {
        ...state,
        error: action.payload,
      };
    },
    attendanceClear(state) {
      return {
        ...state,
        attendance:null
      }
    },
    // attendanceAdminSuccess(state, action) {
    //   return {
    //     ...state,
    //     attendances: action.payload,
    //   };
    // }
  },
});
export const attendanceActions = attendanceSlice.actions;
export default attendanceSlice.reducer;
//このstoreはおそらく，indexjsなどでstoreを使える際に必要なため作成している．
